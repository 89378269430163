import "./AssignmentRow.scss";
import DateTime from "./DateTime";
import AppContext from "../AppContext";
export default function AssignmentRow({assignment, togglePin, open, essentials, changeStatus, href, showPopup, closePopup, typeMarker, className}) {
    const appContext = AppContext.getInstance();

    /**
     *
     * @param list
     * @param e MouseEvent
     */
    return (
        <li className={"assignment-row " + className} onClick={ () => open(assignment.id) }>
            <div className="number-and-picture-wrapper">
                <div className="number-wrapper" onClick={(e) => {e.stopPropagation(); changeStatus(assignment)}}>
                    <div className="status number" data-status={assignment.status}>
                        { typeMarker ? <span className="assignmentTypeMarker">{typeMarker}</span> : '' }
                        { assignment.id }
                    </div>
                </div>
                <div className="picture">
                    <img src={ assignment.imageUrl } alt=""
                         onMouseEnter={(e) => showPopup && assignment.imagePreviewUrls?.length && showPopup(assignment.imagePreviewUrls, e) }
                         onMouseLeave={() => closePopup && closePopup()}/>
                    { assignment.imagePreviewUrls?.length>1 ? <div className="more-images-bullets">
                        { assignment.imagePreviewUrls.map(href =>
                            <div className='image-bullet'
                               onMouseEnter={(e) => showPopup && showPopup(href, e) }
                               onMouseLeave={() => closePopup && closePopup()}>
                            </div>
                          )
                        }
                    </div> : ''
                    }
                </div>
            </div>
            <div className="info">
                {/*<div className="status_wrapper" onClick={(e) => {e.stopPropagation(); changeStatus(assignment)}}>*/}
                {/*    <div className={"status"} data-status={assignment.status}></div>*/}
                {/*</div>*/}
                <a className="name" href={href}>
                    { assignment.name }
                </a>
                { assignment.tasksNumber ? <span className='badge' data-tooltip-id="common-tooltip" data-tooltip-content="Niewykonane zadania">{assignment.tasksNumber}</span> : ''}
                { assignment.notifications?.length ? <span className='notifications badge' data-tooltip-id="common-tooltip" data-tooltip-content="Powiadomienia"><i class="fa fa-bell"></i> {assignment.notifications.length}</span> : ''}
                <div className="owner">
                    { assignment.confidential ? <i className="fa fa-eye-slash confidential-icon"></i>: ''}
                    { assignment.client } { essentials ? `/ prowadzone przez ${ assignment.owner }` : ''}
                </div>
            </div>
            { !essentials ? (
                <div className="owners">
                    <div className="owned_by"><span className="label">Prowadzone przez</span> <b>{assignment.owner }</b></div>
                    {assignment.coowners ? <div className="coowned_by"><span className="label">Współprowadzone przez</span> <b>{assignment.coowners }</b></div> : ''}
                </div>
            ):''}
            { !essentials && assignment.hasOwnProperty('publishers') ? (
                <div className="assignee">
                    {assignment.publishers ? <div className="publishers"><span className="label">Realizowane przez</span> <b>{assignment.publishers }</b></div> : '' }
                </div>
            ):''}
            <div className="dates">
                <div className="date-created"><DateTime label="Utworzone:" date={assignment.createdAt} withTime={!essentials}/></div>
                { !essentials ? (<div className="date-updated"><DateTime label="Zmodyfikowane:" date={assignment.updatedAt} withTime={!essentials}/></div>) : '' }
                { assignment.touchedAt? <div className="touched"><DateTime label="Ost. aktywność:" date={assignment.touchedAt} withTime={!essentials}/></div> : '' }
                <div className="deadline"><DateTime label="Deadline" date={assignment.deadline} pastClass={'obsolete'}/></div>
            </div>
            <div className='pin-wrapper' onClick={(e) => {e.stopPropagation(); togglePin(assignment.id)}}>
                <img src="/ui/icons-black/pin.svg" alt="" className={assignment.pinned ? 'active' : ''}/>
            </div>
        </li>
    )
}
