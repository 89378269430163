import React, {useContext, useEffect} from "react";
import RecentAssignments from "./RecentAssignments";
import {SharedContext} from "../index";

function RecentAssignmentsModal() {
    const {setModal, closeModal} = useContext(SharedContext);

    function tabListener(e) {
        const IS_MAC_OS = navigator.platform.startsWith('Mac');
        if (e.key === 'Tab' && e.altKey && IS_MAC_OS
            || e.key === 'Tab' && e.ctrlKey && !IS_MAC_OS
            || e.key === '`' && e.ctrlKey && !IS_MAC_OS
            || e.key === '`' && e.altKey && !IS_MAC_OS
        ) {
            setModal(<RecentAssignments/>);
            e.stopPropagation();
            e.preventDefault();
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', tabListener);
        return () => {
            document.removeEventListener('keydown', tabListener);
        }
    }, []);

}

export default RecentAssignmentsModal;
