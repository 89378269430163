import {useEffect, useRef, useState} from "react";

export default function useImagePopup() {
    const [imageSource, setImageSource] = useState(null);
    const [currentImageSource, setCurrentImageSource] = useState(null);
    const popupWrapper = useRef();
    const thumbnail = useRef();
    const SHIFT = 10;
    let initialX, initialY;

    useEffect(() => {
        if (currentImageSource) {
            createPopupWrapperIfNecessary();
            if (popupWrapper.current && popupWrapper.current.querySelector('img')) {
                popupWrapper.current.querySelector('img').src = currentImageSource;
            }
            thumbnail.current?.addEventListener('mousemove', movePreview);
        }
        else {
            popupWrapper.current?.remove();
            thumbnail.current?.removeEventListener('mousemove', movePreview);
            clearActiveBullet();
        }
        return () => {
            popupWrapper.current?.remove();
            popupWrapper.current = null;
            thumbnail.current?.removeEventListener('mousemove', movePreview);
        }
    }, [imageSource, currentImageSource]);

    function chooseImage(list, e) {
        const picture = e.currentTarget;
        let mouseX = e.nativeEvent?.offsetX || e.offsetX;
        const segmentWidth = picture.width / list.length;
        const segment = Math.max(0, Math.min(list.length - 1, Math.floor(mouseX / segmentWidth)));
        const wrapper = picture.parentElement;
        const bullets = wrapper?.querySelector(".more-images-bullets");
        if (bullets) {
            for (let child of bullets.children) child.classList.remove('active');
            bullets.children[segment].classList.add('active')
        }
        return(list[segment]);
    }

    function clearActiveBullet() {
        const bullets = thumbnail.current?.parentElement?.querySelector(".more-images-bullets");
        if (bullets) {
            for (let child of bullets.children) child.classList.remove('active');
        }
    }

    function showPopup(imageSrc, event) {
        setImageSource(imageSrc);
        if (Array.isArray(imageSource)) {
            setCurrentImageSource(chooseImage(imageSrc, event));
        }
        else {
            setCurrentImageSource(imageSrc);
        }
        thumbnail.current = event.currentTarget;
        initialX = event.clientX;
        initialY = event.clientY;
    }

    function closePopup() {
        setImageSource(null);
        setCurrentImageSource(null);
    }

    function movePreview(event) {
        let element = popupWrapper.current;
        if (element) {
            setPosition(event.clientX, event.clientY)
            if (Array.isArray(imageSource)) {
                setCurrentImageSource(chooseImage(imageSource, event));
            }
        }
    }

    function createPopupWrapperIfNecessary() {
        if (!popupWrapper.current) {
            let element = document.createElement('div');
            element.style.position = 'fixed';
            element.style.zIndex = '12';
            element.style.padding = '3px';
            element.style.background = 'white';
            element.style.border = '1px solid rgba(0,0,0,.067)';
            element.style.borderRadius = '3px';

            let image = document.createElement('img');
            image.style.display = 'block';
            image.style.maxWidth = 'min(400px, 66vw)';
            image.style.maxHeight = 'min(400px, 66vh)';
            image.style.height = 'auto';

            element.append(image);
            document.body.append(element);
            popupWrapper.current = element;
        }
    }
    
    function setPosition(x, y) {
        const element = popupWrapper.current;
        if (element) {
            let left = x + SHIFT;
            if (left + element.offsetWidth > window.innerWidth) {
                left = window.innerWidth - element.offsetWidth;
            }
            element.style.left = `${left}px`;

            let top = y + SHIFT;
            if (top + element.offsetHeight > window.innerHeight) {
                top = window.innerHeight - element.offsetHeight;
            }
            element.style.top = `${top}px`;
        }
    }

    return [showPopup, closePopup];
}
