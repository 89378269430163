import React, {useContext, useEffect, useRef, useState} from "react";
import AssignmentRow from "./AssignmentRow";
import useAssignmentsEventListeners from "../hooks/useAssignmentsEventListeners";
import useImagePopup from "../hooks/use-image-popup";
import useDesignAssignmentsApi from "../hooks/useDesignAssignmentsApi";

export default function DesignAssignments({assignments, total, essentials, setAssignments}) {

    let wrapper = useRef(null);
    const [showPopup, closePopup] = useImagePopup();
    const {toggleDesignAssignmentPin, openDesign, requestStatusChange} = useDesignAssignmentsApi(assignments, setAssignments);
    useAssignmentsEventListeners({assignmentType: 'design_zlecenie', setAssignments});

    function updateTotal(count) {
        if (wrapper && wrapper.current) wrapper.current.dispatchEvent(new CustomEvent('setBadge', {detail: count, bubbles: true}));
    }

    return (
        <ul className="assignments-wrapper" ref={wrapper}>
            { assignments ? assignments.map((assignment, i) => AssignmentRow({
                assignment,
                togglePin: toggleDesignAssignmentPin,
                open: openDesign,
                essentials,
                changeStatus: requestStatusChange,
                href: `/design/${assignment.id}/show`,
                showPopup,
                closePopup
            })) : '' }
        </ul>
    )
}
