import AssignmentStatusPopup from "../components/AssignmentStatusPopup";
import React, {useContext} from "react";
import {SharedContext} from "../index";

function usePublishingAssignmentsApi(assignments, setAssignments) {

    let ctx = useContext(SharedContext);
    function togglePublishingAssignmentPin(id) {
        fetch(`/api/assignments/${id}/pin/toggle`, {credentials: "same-origin"})
            .then(resp => resp.json())
            .then(json => {
                if (json.status === 'ok') {
                    const assignmentIndex = assignments.findIndex(a => a.id === id);
                    const newAssignments = [...assignments];
                    newAssignments.splice(assignmentIndex, 1, json.assignment);
                    setAssignments(newAssignments);
                }
            });
    }

    function openAssignment(id) {
        window.location.href = `/zlecenie/${id}`;
    }

    function changeStatus(assignmentId, statusSlug) {
        fetch(`/api/assignments/${assignmentId}/status`, {credentials: "same-origin", method: 'put', body: JSON.stringify({status: statusSlug})})
            .then(resp => resp.json())
            .then(json => {
                if (json.status === 'ok') {
                    const assignmentIndex = assignments.findIndex(a => a.id === assignmentId);
                    const newAssignments = [...assignments];
                    newAssignments.splice(assignmentIndex, 1, json.assignment);
                    setAssignments(newAssignments);
                }
            });
    }

    function requestStatusChange(assignment) {
        ctx.setModal(<AssignmentStatusPopup assignmentType="publishing" assignment={assignment} onSelect={handleStatusSelect}/> );
    }

    function handleStatusSelect(assignmentId, statusSlug) {
        changeStatus(assignmentId, statusSlug);
        console.log(`Changing status of assignment ${assignmentId} to ${statusSlug}`);
        ctx.closeModal();
    }

    return {
        togglePublishingAssignmentPin,
        openAssignment,
        requestStatusChange,
        changeStatus
    }
}

export default usePublishingAssignmentsApi;
