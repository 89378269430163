import AssignmentStatusPopup from "../components/AssignmentStatusPopup";
import React, {useContext} from "react";
import {SharedContext} from "../index";

function useDesignAssignmentsApi(assignments, setAssignments) {

    let ctx = useContext(SharedContext);
    function toggleDesignAssignmentPin(id) {
        fetch(`/api/designs/${id}/pin/toggle`, {credentials: "same-origin"})
            .then(resp => resp.json())
            .then(json => {
                if (json.status === 'ok') {
                    const assignmentIndex = assignments.findIndex(a => a.id === id);
                    const newAssignments = [...assignments];
                    newAssignments.splice(assignmentIndex, 1, json.assignment);
                    setAssignments(newAssignments);
                }
            });
    }

    function openDesign(id) {
        window.location.href = `/design/${id}/show`;
    }

    function changeStatus(assignmentId, statusSlug) {
        fetch(`/api/design/${assignmentId}/status`, {credentials: "same-origin", method: 'put', body: JSON.stringify({status: statusSlug})})
            .then(resp => resp.json())
            .then(json => {
                if (json.status === 'ok') {
                    const assignmentIndex = assignments.findIndex(a => a.id === assignmentId);
                    const newAssignments = [...assignments];
                    newAssignments.splice(assignmentIndex, 1, json.assignment);
                    setAssignments(newAssignments);
                }
            });
    }

    /** ok */
    function requestStatusChange(assignment) {
        ctx.setModal(<AssignmentStatusPopup assignmentType="design" assignment={assignment} onSelect={handleStatusSelect}/> );
    }

    function handleStatusSelect(assignmentId, statusSlug) {
        changeStatus(assignmentId, statusSlug);
        console.log(`Changing status of assignment ${assignmentId} to ${statusSlug}`);
        ctx.closeModal();
    }

    return {
        toggleDesignAssignmentPin,
        openDesign,
        requestStatusChange,
        changeStatus
    }
}

export default useDesignAssignmentsApi;
