import React, {useContext, useEffect, useRef, useState} from "react";
import AssignmentRow from "./AssignmentRow";
import {SharedContext} from "../index";
import AssignmentStatusPopup from "./AssignmentStatusPopup";
import useAssignmentsEventListeners from "../hooks/useAssignmentsEventListeners";
import useImagePopup from "../hooks/use-image-popup";
import usePublishingAssignmentsApi from "../hooks/usePublishingAssignmentsApi";

function PublishingAssignments({assignments, total, essentials, setAssignments}) {
    let wrapper = useRef(null);

    const [showPopup, closePopup] = useImagePopup();
    useAssignmentsEventListeners({assignmentType: 'zlecenia_zlecenie', setAssignments});
    const {togglePublishingAssignmentPin, openAssignment, requestStatusChange} = usePublishingAssignmentsApi(assignments, setAssignments);

    function updateTotal(count) {
        if (wrapper && wrapper.current) wrapper.current.dispatchEvent(new CustomEvent('setBadge', { detail: count, bubbles: true }));
    }

    return (
        <ul className="assignments-wrapper" ref={ wrapper }>
            { assignments ? assignments.map((assignment,i) => AssignmentRow({
                assignment,
                togglePin: togglePublishingAssignmentPin,
                open: openAssignment,
                essentials,
                changeStatus: requestStatusChange,
                href: `/zlecenie/${assignment.id}`,
                showPopup,
                closePopup
            })) : '' }
        </ul>
    );

}

export default PublishingAssignments;
